import React, { useState } from 'react';

const Accordion = ({ content }) => {
    const [isActive, setIsActive] = useState(false);
    const handleClick = (e) => {
        e.preventDefault();
        //
        setIsActive(!isActive);
        console.log('The link was clicked.');
    }
    return (
        <>
            {isActive && <div className="accordion-content">{content}</div>}
            <a href="#" onClick={(e) => handleClick(e)}>{isActive ? 'Read less' : 'Read more'}</a>
        </>
    );
};

export default Accordion;